import SaveButtonArea from '@/components/SaveButtonArea/SaveButtonArea.vue';
import Modal from '@/components/Modal/Modal.vue';
import SkeletonLoadingImage from '@/components/SkeletonLoadingImage/SkeletonLoadingImage.vue';

import CreativeDetailService from '@/services/API/creative_detail.service';

import CommonConst from '../../../../constants/CommonConst';

import { mapState } from 'vuex';

const DEFAULT_CONTENT = {
	answer_content: undefined,
	answer_deadline: undefined,
	answered_flag: undefined,
	creative_id: undefined,
	image_file_name: undefined,
	question: undefined,
	update_user: undefined,
};

function isValidHttpUrl(string) {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
}

export default {
	name: 'AnswerModal',
	components: { SaveButtonArea, Modal, SkeletonLoadingImage },
	props: {
		selectedQuestion: {
			type: Object,
		},
		updateUser: {
			type: Number,
		},
		creativeId: {
			type: Number,
		},
		isDownloadAvailable: {
			type: Boolean,
			default: true,
		}
	},
	data() {
		return {
			answerContent: '',
			selectQuestionId: '',
			questionContent: '',
			imageUrl: '',
			remarksContent: '',
			deadline: '',
			uploadImageKey: '',
			disabledSendBtn: true,
			answeredFlag: {
				type: Boolean,
			},

			pdfPreviewModal: 'creative-preview-pdf-modal',
			pdfPreviewer: {
				url: '',
			},

			pdfFileIcon: CommonConst.PDF_FILE_ICON,
		};
	},
	computed: {
		...mapState(['preview', 'clientMode',]),
		isPdf() {
			return this.selectedQuestion && this.selectedQuestion.fileType === 'pdf';
		},
	},
	watch: {
		selectedQuestion: {
			handler() {
				this.answeredFlag = Boolean(this.selectedQuestion['answeredFlag']);
				this.questionContent = this.selectedQuestion['questionContent'];
				this.imageUrl = this.selectedQuestion['imageUrl'];
				this.deadline = this.selectedQuestion['answerDeadline'];
				this.uploadImageKey = this.selectedQuestion['fileKey'];
				this.selectQuestionId = this.selectedQuestion['questionIndex'];
				this.answerContent = this.selectedQuestion['answerContent'];
				this.remarksContent = this.selectedQuestion['comment'];
			},
		},
		answerContent: {
			handler(newVal) {
				if (newVal === '') {
					this.disabledSendBtn = true;
				} else {
					this.disabledSendBtn = false;
				}
			},
		},
	},
	updated() {
		this.updateContentURL();
	},
		
	methods: {
		onDownloadPdfFileFromChild() { 
			this.$emit('on-download-pdf-file', this.pdfPreviewer);
		},
		updateContentURL() {
			let questionContent = document.getElementById('question-input')
			if (questionContent == null) {
				setTimeout(this.updateContentURL, 100);
			}
			else {
				let stringList = this.questionContent.split(" ");
				stringList = stringList.map((string) => {
					let stringBr = string.split("\n");
					if(stringBr.length > 1) {
						for (let i = 0; i < stringBr.length; i++) {
							if (isValidHttpUrl(stringBr[i])) {
								let brHeader = stringBr[i - 1] ? "" : "\n";
								let brFooter = stringBr[i + 1] ? "" : "\n";
								stringBr[i] = brHeader + "<a href='" + stringBr[i] + "' target='_blank'>" + stringBr[i] + "</a>" + brFooter;
							}
						}
						return stringBr.join("\n");
					}
					else {
						if (isValidHttpUrl(string)) {
							return "\n<a href='" + string + "' target='_blank'>" + string + "</a>\n";
						}
						else {
							return string
						}
					}
				})
				questionContent.innerHTML = stringList.join(' ');
				let answerContent = document.getElementById('question-answer')
				if (answerContent != null) {
					let stringList = this.answerContent.split(" ");
					stringList = stringList.map((string) => {
						let stringBr = string.split("\n");
						if(stringBr.length > 1) {
							for (let i = 0; i < stringBr.length; i++) {
								if (isValidHttpUrl(stringBr[i])) {
									let brHeader = stringBr[i - 1] ? "" : "\n";
									let brFooter = stringBr[i + 1] ? "" : "\n";
									stringBr[i] = brHeader + "<a href='" + stringBr[i] + "' target='_blank'>" + stringBr[i] + "</a>" + brFooter;
								}
							}
							return stringBr.join("\n");
						}
						else {
							if (isValidHttpUrl(string)) {
								return "\n<a href='" + string + "' target='_blank'>" + string + "</a>\n";
							}
							else {
								return string
							}
						}
					})
					answerContent.innerHTML = stringList.join(' ');
				}
			}
		},
		onClose() {
			this.$bvModal.hide('answer-modal');
		},
		onDownload() {
			console.log('clicked');
		},

		onPreview() {
			if (this.isPdf) {
				console.log('preview');
				this.pdfPreviewer.url = this.imageUrl;
				this.$bvModal.show(`${this.pdfPreviewModal}`);
			}
		},

		async onSendAnswer() {
			let defaultQuestion = { ...DEFAULT_CONTENT };
			let answerQuestion = this._filterQuestionContent(defaultQuestion);
			answerQuestion['id'] = this.selectQuestionId;
			answerQuestion['answer_content'] = this.answerContent;
			answerQuestion['answered_flag'] = 1;
			let response = await CreativeDetailService.update(this.selectQuestionId, {
				doc_content: answerQuestion,
			});

			if (response.status !== 200) {
				throw 'Update question failed';
			}

			// this.$emit('on-send-answer', true);
			this.$emit('on-send-answer', answerQuestion);

			this.$bvModal.hide('answer-modal');
		},

		_filterQuestionContent(defaultQuestion) {
			defaultQuestion['update_user'] = parseInt(this.updateUser);
			defaultQuestion['creative_id'] = parseInt(this.creativeId);
			defaultQuestion['question'] = this.questionContent;
			defaultQuestion['image_file_name'] = this.uploadImageKey;
			defaultQuestion['answered_flag'] = 0;
			defaultQuestion['answer_deadline'] = new Date(this.deadline);
			return defaultQuestion;
		},
	},
};