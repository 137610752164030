import ScreenHeader from '@/components/ScreenHeader/ScreenHeader.vue';
import ProjectDeadline from '@/components/ProjectDeadline/ProjectDeadline.vue';
import BottomButton from '@/components/BottomButton/BottomButton.vue';
import CountDown from '@/components/CountDown/CountDown.vue';
import AnswerModal from './AnswerModal/AnswerModal.vue';
import ItemList from '@/components/ItemList/ItemList.vue';

import socketClient from '@/services/SOCKET';
import CreativeService from '@/services/API/creative.service';
import TransferService from '@/services/API/transfer.service';

import updateProjectStatusMixin from '@/mixin/updateProjectStatus';
import { sendNotify, toLineMessageTemplate } from '@/utilities';
import { getFileName, getFileExtention } from '@/store/modules/File';
import CommonConst from '@/constants/CommonConst';
import emitSoundMixin from '@/mixin/emitSound';
import downloadPdfMixin from '../../../mixin/downloadPdfMixin';

import moment from 'moment';
import { mapState } from 'vuex';

const DEFAULT_ITEM = {
	title: undefined,
	isChanged: undefined,
	dateData: undefined,
	nonfilterDateData: undefined,
	itemId: undefined,
	gridCols: undefined,
	gridStatus: undefined,
	showDateData: true,
	showTitle: true,
	statusIcon: 'QuestionStatus.png',
	isShowIcon: false,
	isColorChange: true,
};
export default {
	name: 'CreativeScreen',
	mixins: [updateProjectStatusMixin, emitSoundMixin, downloadPdfMixin],
	components: {
		ScreenHeader,
		ProjectDeadline,

		CountDown,
		ItemList,
		AnswerModal,
		BottomButton,
	},
	data() {
		return {
			screenIcon: 'creative-icon',
			screenName: 'クリエイティブ',
			isShowMainBody: false,
			deadline: '',
			creativeData: {},
			directLink: 'proposal',
			middleContent: ' 様 ／ ',
			counterDate: '',
			questionList: [],
			selectedQuestion: {},
			creativeId: undefined,
			expiration: '31536000',
			showCountDown: true,
			showStatus: false,
			mailContent: '',
			mailTitle: '',
			subject: '',
			isSendCreator: false,

			pdfPreviewer: {
				url: '',
			},
		};
	},
	computed: {
		// Get projectId, userId from store
		...mapState([
			'projectId',
			'userId',
			'projectInfo',
			'clientInfo',
			'creatorInfo',
			'managementMasterInfo',
			'schedule',
			'appLogo',
			'appLogoPlain',
		]),
		destinations() {
			return [this.clientInfo['email_address'], this.creatorInfo['email_address']];
		},
		mailTemplate() {
			return {
				destinations: this.isSendCreator
					? [this.creatorInfo['email_address']]
					: [this.clientInfo['email_address']],
				sender: this.managementMasterInfo['sender_email_address'],
				subject: this.subject,
				template: this.isSendCreator ? 'processmail_creator' : 'processmail_client',
				template_params: {
					projectName: this.projectInfo['project_name'],
					projectId: this.projectId,
					screenName: this.screenName,
					clientName: this.clientInfo['client_name'],
					clientId: this.clientInfo['id'],
					emailContent: this.mailContent,
					sendMailDate: moment().format('LLL'),
					appLogo: this.appLogo,
					appLogoFooter: this.appLogoPlain,
					processingScreenUrl: this.isSendCreator
						? `${this.projectInfo['process_creator_url']}creative`
						: `${this.projectInfo['process_client_url']}creative`,
					clientRegisterParams:
						`register_name=${this.clientInfo['client_name']}&` +
						`email_address=${this.clientInfo['email_address']}&phone_number=${this.clientInfo['phone_number']}` +
						`&account_type=client&client_id=${this.clientInfo['id']}&responsible_name=${this.clientInfo['responsible_name']}`,
				},
			};
		},
		lineMessage() {
			return toLineMessageTemplate({
				title: `[CoMoDe]［${this.clientInfo['client_name']}様 / ${this.projectInfo['project_name']}］`,
				message: this.mailContent,
				currentUrl: this.isSendCreator
					? `${this.projectInfo['process_creator_url']}creative`
					: `${this.projectInfo['process_client_url']}creative`,
			});
		},
		notifyContent() {
			return this.projectInfo['notify_method'] == 0
				? this.mailTemplate
				: this.projectInfo['notify_method'] == 1
				? this.lineMessage
				: {
						lineContent: this.lineMessage,
						mailContent: this.mailTemplate,
				  }; // eslint-disable-line no-mixed-spaces-and-tabs
		},
	},
	methods: {
		onDownloadPdfFileFromParent(pdfUrl) { 
			this.pdfPreviewer = pdfUrl
			this.onDownloadPdfFile();
		},
		//__FOR ANSWERED QUESTION__//
		async onAnswer(data) {
			this.$store.commit('setIsAppProcessing', true);

			const creativeData = { ...this.creativeData };
			const creativeDetails = creativeData['creative_details'];
			const selectedQuestion = creativeDetails.find((x) => x.id === data['id']);
			const imageUrl = selectedQuestion['image_file_name']
				? await this._getPreviewImgUrl(
						selectedQuestion['image_file_name'],
						this.expiration
				  ) // eslint-disable-line no-mixed-spaces-and-tabs
				: '';
			const question = {
				answeredFlag: selectedQuestion['answered_flag'],
				comment: selectedQuestion['comment'],
				questionContent: selectedQuestion['question'],
				imageUrl: imageUrl,
				answerDeadline: moment(selectedQuestion['answer_deadline']).format(
					'YYYY/MM/DD'
				),
				questionIndex: selectedQuestion['id'],
				fileKey: selectedQuestion['image_file_name'],
				answerContent: selectedQuestion['answer_content'],
				fileType: getFileExtention(selectedQuestion['image_file_name']),
				fileName: getFileName(selectedQuestion['image_file_name']),
			};
			this.selectedQuestion = question;
			this.$bvModal.show('answer-modal');

			this.$store.commit('setIsAppProcessing', false);
		},

		async onUpdateAnswer(data) {
			if (data) {
				this.$store.commit('setIsAppProcessing', true);

				let creativeData = { ...this.creativeData };
				this._filterQuestionList(creativeData);
				await this._getCreativeData(this.projectId);

				socketClient.send('update_creative_question_list', {
					userId: this.userId,
					projectId: this.projectId,
					content: true,
				});
				let deadline = moment(data['answer_deadline']).format('YYYY年MM月DD日');
				// send mail case 37
				this.mailTitle = `[CoMoDe]［${this.clientInfo['client_name']}様 / ${this.projectInfo['project_name']}］追加質問の回答期限が過ぎました`;
				this.mailContent = `追加質問の回答が届きました。\n(回答期限 ${deadline})`;
				this.subject = `【CoMoDe】[${this.clientInfo['client_name']}/${this.projectInfo['project_name']}] 追加質問の回答期限が過ぎました`;
				this.isSendCreator = true;
				sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
					this.projectInfo['responsible_user'],
				]);
				this.$emit('update-creative-question-list', true);

				this.$store.commit('setIsAppProcessing', false);
			}
		},

		async onHandleTimeOut() {
			this.$store.commit('setIsAppProcessing', true);

			// Abort when creative is dose not existed
			if (!this.creativeId) {
				this.$store.commit('setIsAppProcessing', false);

				return;
			}

			if (this.creativeStatus !== CommonConst.SCREEN_STATUS.FINISH) {

				// emit sound when countdown is done
				this.emitMixinProcessingSound({isSocketIncluded:  false});
				this.showStatus = false;
				let creativeStatus = {
					project_id: this.projectId,
					status: CommonConst.SCREEN_STATUS.FINISH,
					update_user: this.userId,
				};

				await this._updateCreative(this.projectId, creativeStatus);

				await this.updateProjectStatus(
					this.projectId,
					CommonConst.SCREEN_ID.CREATIVE + 1
				);
				let confirmInfo = {
					screenId: CommonConst.SCREEN_ID.CREATIVE,
					confirmInfo: true,
				};
				this.$emit('on-send-confirm-info', confirmInfo);
				this.$router.push(this.directLink);
			}

			this.$store.commit('setIsAppProcessing', false);
		},
		onHandleTimeRunning() {
			this.showCountDown = true;
		},

		/**
		 * Filter question list
		 * @param {Object} decisionData
		 */
		async _filterQuestionList(creativeData) {
			let creativeDetails = [];
			let questionList = [];
			creativeDetails = creativeData['creative_details'];
			if (creativeDetails && creativeDetails.length > 0) {
				this.isShowItemList = true;
				for (let i = 0; i < creativeDetails.length; i++) {
					let defaultQuestion = { ...DEFAULT_ITEM };
					defaultQuestion['isChanged'] = Boolean(creativeDetails[i]['answered_flag']);
					if (defaultQuestion['isChanged']) {
						defaultQuestion['gridCols'] = 3;
						defaultQuestion['gridStatus'] = 'normal';
						defaultQuestion['isShowIcon'] = true;
						defaultQuestion['dateData'] = '回答済み';
					} else {
						defaultQuestion['dateData'] = this._filterDate(
							creativeDetails[i]['answer_deadline']
						);
						defaultQuestion['gridCols'] = 2;
						defaultQuestion['gridStatus'] = 'normal';
						defaultQuestion['showBadge'] = true;
					}

					defaultQuestion['title'] = creativeDetails[i]['question'];
					defaultQuestion['nonfilterDateData'] = creativeDetails[i]['answer_deadline'];
					defaultQuestion['itemId'] = creativeDetails[i]['id'];

					questionList.push(defaultQuestion);
				}
				return questionList;
			}
			return questionList;
		},

		/**
		 *
		 * @param {String} imgKey: path of image get from API
		 * @param {Interge} expiration: time of showing image
		 * @returns
		 */
		async _getPreviewImgUrl(imgKey, expiration) {
			try {
				let response = await TransferService.get(imgKey, expiration);
				if (!response || response.status !== 200) {
					throw 'Get image url failed!';
				}

				console.log('%c Get image url successfully!', 'color: red');
				let imgUrl = response.data.link;
				return imgUrl;
			} catch (error) {
				console.log(error);
			}
		},

		//___FOR GET COUNTDONW__//
		/**
		 * Filter Date to String
		 * @param {DateTime} date
		 * @returns
		 */
		_filterDate(date) {
			let momentDate = moment(date);
			let year = momentDate.format('YYYY');
			let month = momentDate.format('MM');
			let day = momentDate.format('DD');
			return `回答期限 [${year} 年 ${month} 月 ${day} 日]`;
		},

		/**
		 * Get Creative Data from API
		 * @param {Integer} projectId
		 */
		async _getCreativeData(projectId) {
			let response = await CreativeService.get(projectId);
			if (response.status !== 200) {
				throw 'Get Creative Data failed!';
			}

			this.creativeData = response.data;

			if (Object.keys(this.creativeData).length === 0) {
				return;
			}

			this.showStatus = true;
			this.creativeId = response.data['id'];
			this.creativeStatus = response.data['status'];
			this.isShowMainBody = true;
			if (this.creativeData['scheduled_date']) {
				this.counterDate = this.creativeData['scheduled_date'];
			}

			this.deadline = this._filterDate(this.creativeData['scheduled_date']);
			this.questionList = await this._filterQuestionList(this.creativeData);
			this._checkStatus(this.creativeStatus);
		},

		/**
		 * Update Creative
		 * @param {Integer} projectId Project ID
		 * @param {Object} content updating data
		 */
		async _updateCreative(projectId, content) {
			try {
				let response = await CreativeService.put(projectId, {
					doc_content: content,
				});
				if (!response || response.status !== 200) {
					throw 'Update Creative failed';
				}
				console.log('%c Update Creative successfully!', 'color: green');
			} catch (error) {
				console.log(`%c ${error}`, 'color: red');
			}
		},

		/**
		 * Check screen status to show and hide elements
		 * @param {String} status Screen status
		 */
		_checkStatus(status) {
			if (
				status === CommonConst.SCREEN_STATUS.FINISH ||
				status === CommonConst.SCREEN_STATUS.TIME_OUT
			) {
				this.showCountDown = false;
				this.showStatus = false;
			}
		},
	},

	async created() {
		socketClient.listen('new_creative_created', async (data) => {
			console.log('new_creative_created', data);
			if (data) {
				this.$store.commit('setIsAppProcessing', true);

				this.isShowMainBody = true;
				await this._getCreativeData(this.projectId);
				this.deadline = this._filterDate(this.creativeData['scheduled_date']);

				this.$store.commit('setIsAppProcessing', false);
			}
		});

		socketClient.listen('new_creative_question_list_updated', async (data) => {
			if (data) {
				this.$store.commit('setIsAppProcessing', true);

				console.log('received item list');
				await this._getCreativeData(this.projectId);

				this.$store.commit('setIsAppProcessing', false);
			}
		});
	},

	async mounted() {
		this.$store.commit('setIsAppProcessing', true);

		moment.locale('ja');
		await this._getCreativeData(this.projectId);
		this.$emit('start-creative-countdown')
		this.$store.commit('setIsAppProcessing', false);
	},
};
